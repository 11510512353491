<!-- eslint-disable -->
<template>
  <!-- Content Section Start -->
  <div class="mt-6">
    <!-- Page title Section Start -->
    <div id="page-title" class="page-title-block" :style="style">
      <div class="container">
        <h1 class="post-title">Admin panel</h1>
      </div>
    </div>
    <!-- Page title Section End -->
    <!-- Benefits Start -->
    <div id="page-content" class="admin-section">
      <div class="container">
        <div class="row">
          <div class="col-md-3 sidebar">
            <div class="widget-area">
              <aside id="categories-1" class="widget widget_categories">
                <h4 class="widget-title">Profile</h4>
                <ul>
                  <li
                    v-for="link in navLinks"
                    :key="link.id"
                  >
                    <router-link
                      :to="link.to"
                    >
                      {{ $t(link.label) }}
                    </router-link>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
          <div class="col-md-9 order-first order-md-last">
            <article class="post">
              <div class="container">
                <div class="contact-section">
                  <div class="col-md-12 text-center">
                    <div class="sec-title text-center">
                      <h3>Admin panel</h3>
                    </div>
                    <div class="row"
                    >
                      <div class="col-md-5">
                        <el-leaderboard :users="getLeaderboards" />
                        <div class="form-group">
                          <button type="button" class="btn btn-save" @click="onShowTotal">Show total</button>
                        </div>
                      </div>
                      <div class="col-md-7 user-item">
                        <div v-for="user in getUsers" :key="user.id" class="user-item-container">
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label>Username: <span>{{ user.username }}</span></label>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label>Role:</label>
                              <input v-model="user.role" type="text" class="form-control" placeholder="Username">
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label>Current score: {{ user.current_score }}</label>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label>Score:</label>
                              <input v-model="user.score" type="text" class="form-control" placeholder="Current Score">
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label>Current role: {{ user.role }}</label>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label>Role:</label>
                              <input v-model="user.newRole" type="text" class="form-control" placeholder="Put the new role">
                            </div>
                          </div>
                          <div class="form-group">
                            <button type="button" class="btn btn-save" @click="onSave(user, 'score')">Update Score</button>
                            <button type="button" class="btn btn-save ml-4" @click="onSave(user, 'role')">Update Role</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1" />
                </div>
              </div>
              <!-- Hero Section End -->
            </article>
          </div>
        </div>
      </div>
    </div>
    <!-- Benefits End -->
  </div>
</template>

<script>
  /* eslint-disable */
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { nanoid } from 'nanoid';
  import { Leaderboard } from '@/components/shared/leaderboard';
  import { PROFILE_LINKS } from '@/constants/navbar-links';
  import backgroundBanner from '@/assets/jpg/profile.jpg';
  import { isEqual } from 'lodash-es';

  export default Vue.extend({
    name: 'elros-profile-admin',
    components: {
      'el-leaderboard': Leaderboard,
    },
    data: () => ({
      style: { backgroundImage: `url(${backgroundBanner})` },
    }),
    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
        getUsers: 'session/getUsers',
        getLeaderboards: 'session/getLeaderboards',
      }),
      navLinks() {
        const isAdmin = this.getUser.role === 'admin';

        if (isAdmin) {
          const hasAdmin = PROFILE_LINKS.find(({ label }) => label === 'Admin');

          if (!hasAdmin) {
            PROFILE_LINKS.push({ label: 'Admin', to: '/admin', needsAuth: true });
          }
        }

        return PROFILE_LINKS
          .filter(item => item.needsAuth)
          .map(item => ({ ...item, id: nanoid() }));
      },
    },
    created() {
      this.fetchUsers();
      this.fetchLeaderboards('current_score');
    },
    methods: {
      ...mapActions({
        fetchUsers: 'session/fetchUsers',
        fetchLeaderboards: 'session/fetchLeaderboards',
        incrementScore: 'session/incrementScore',
        updateRole: 'session/updateRole',
      }),
      onShowTotal() {
        this.fetchLeaderboards('total_score');
      },

      onSave(user, type) {
        const { id } = user;
        const score = user.score ? parseInt(user.score, 10) : 0;
        const role = user.role ? user.newRole : 'user';
        let data;

        if (type === 'score') {
          data = {
            score,
            user_ids: [id],
            type: 'users',
          };
          this.incrementScore({ user: data });
        } else {
          data = {
            role,
            user_ids: [id],
            type: 'users',
          };
          this.updateRole({ user: data });
        }
      },
    },
  });
</script>
<style>
.admin-section .user-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.admin-section .user-item .user-item-container {
  border: 1px solid black;
  margin-bottom: 10px;
  padding: 30px;
}

.admin-section .user-item .form-group {
  align-items: center;
  display: flex;
}

.admin-section .user-item .form-group label {
  margin-bottom: 0;
  margin-right: 10px;
  padding: 0;
}

.admin-section .btn-save,
.copy-btn {
  background: #eec05e !important;
  background-color: #eec05e !important;
  border-radius: 4px;
  color: #1d1d1d;
  cursor: pointer;
  font-weight: 700;
  padding: 14px 30px;
  text-transform: uppercase;
}

.admin-section .copy-btn {
  font-weight: 500;
  padding: 8px 16px;
}
</style>
